/*  --- Variable ---  */
$font-size: 17px;
// $header-h: 120px;
// $header-h_mobile: 112px;
$container: 1156px;

/*  --- Padding ---  */

$pad-xxsmall: 8px;
$pad-xsmall: 24px;
$pad-small: 32px;
$pad-med: 42px;
$pad-large: 80px;
$pad-xlarge: 100px;

$pad-med_mobile: 32px;
$pad-large_mobile: 40px;
$pad-xlarge_mobile: 40px;

//COLOR
$white: #ffffff;
$black: #2F2F2F;
$dark: #2F2F2F;
$tan: #F6F1EE;
$grey: #ECE5E0;
$orange: #FF7854;
$primary: $orange;

$text_color: $dark;
