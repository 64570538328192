.anime-title {
    opacity: 0;
    .word {
        white-space: nowrap;
      }
    .letter {
        display: inline-block;
        opacity: 0;
        transform-origin: left bottom;
    }
}

.btn, .anime-texte, .breadcrumb, .anime-steps .step, .anime-steps-opacity .step {
    opacity: 0;
}

.anime-img {
    overflow: hidden;
    opacity: 0;
}