.module-13-testimonial {
    .container {
        max-width: 625px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .txt {
            padding-top: 50px;
            font-style: italic;
            font-size: 1.5em;
            line-height: 150%;
        }

        .logo_wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 40px;
            font-size: 1.3em;
            flex-wrap: wrap;
            img {
                width: 65px;
            }

            *:nth-child(2) {
                margin-left: 22px;
            }
        }
    }
}