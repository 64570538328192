.module-11-cta-banner {
    .banner {
        width: 100%;
        position: relative;
        // min-height: 650px;
        display: flex;
        align-items: center;
        justify-content: center;

        .bg_img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            img {
                @include bg-img;
                filter: grayscale(1);
                z-index: 0;
            }
            .filter {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
            }
        }
        .bg {
            position: absolute;
            top: 110px;
            bottom: 110px;
            width: 90%;
            max-width: 880px;
            background-color: $orange;
            background-image: url(../img/bg-pattern_dark.svg);
            mix-blend-mode: overlay;
            z-index: 1;
            @include break(tablet) {
                top: 60px;
                bottom: 60px;
            }
        }
        .content {
            margin-top: 110px;
            margin-bottom: 110px;
            width: 90%;
            max-width: 880px;
            padding: 100px 10%;
            z-index: 2;
            position: relative;
            color: $white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @include break(tablet) {
                margin-top: 60px;
                margin-bottom: 60px;
            }
            @include break(mobile) {
                padding: 40px 5%;
            }

            .title-h2 {
                font-size: 3.8em;
                @include break(mobile) {
                    font-size: 2.6em;
                }
            }

            .btn {
                margin-top: 40px;
                @include break(tablet) {
                    margin-top: 24px;
                }
            }
        }

    }
}