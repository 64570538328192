.template-single-events-promotions {
    padding-top: 165px;
    @include break(tablet) {
        padding-top: 85px;
    }

    .main-content {
        position: relative;
        border-radius: 4px;
        overflow: hidden;

        .frame {
            pointer-events: none;
            position: absolute;
            top: 40px;
            left: 40px;
            right: 40px;
            bottom: 40px;
            @include break(tablet) {
                top: 20px;
                left: 20px;
                right: 20px;
                bottom: 20px;
            }
            
            .frame_top {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 100px;
                width: 100%;
                @include break(tablet) {
                    height: 25px;
                    path {
                        stroke-width: 4px!important;
                    }
                }
            }
            .frame_bottom {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                transform: rotate(180deg);
                height: 100px;
                width: 100%;
                @include break(tablet) {
                    height: 25px;
                    path {
                        stroke-width: 4px!important;
                    }
                }
            }
            .side1 {
                width: 2px;
                background-color: $orange;
                position: absolute;
                bottom: 100px;
                left: 0;
                right: auto;
                top: 100px;
                @include break(tablet) {
                    bottom: 24.5px;
                    top: 24.5px;
                    width: 1px;
                }
            }
            .side2 {
                width: 2px;
                background-color: $orange;
                position: absolute;
                bottom: 100px;
                right: 0;
                left: auto;
                top: 100px;
                @include break(tablet) {
                    bottom: 24.5px;
                    top: 24.5px;
                    width: 1px;
                }
            }
        }

        .inner {
            padding: 120px;
            display: flex;
            flex-direction: column;
            align-items: center;
            @include break(tablet) {
                padding: 40px;
            }

            >div  {
                width: 70%;
                min-width: 685px;
                @include break(tablet) {
                    min-width: 0;
                    width: 100%;
                }
            }

            .logo {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 55px;

                svg {
                    width: 180px !important;
                    height: auto !important;
                    @include break(mobile) {
                        width: 105px !important;
                    }
                    path {
                        fill: $dark;
                    }
                }
                img {
                    width: 180px !important;
                    height: auto !important;
                    filter: grayscale(1) brightness(0);
                    @include break(mobile) {
                        width: 105px !important;
                    }
                }
            }

            .main-title {
                text-align: center;
                margin-bottom: 15px;
            }

            .date {
                display: flex;
                justify-content: center;
                align-items: center;
                @include break(mobile) {
                    flex-direction: column;
                }
                .icon {
                    width: 54px;
                    margin-right: 14px;
                    @include break(mobile) {
                        width: 54px;
                        margin-right: 0px;
                        margin-bottom: 12px;
                    }
                }
                .info {
                    display: flex;
                    flex-direction: column;
                    @include break(mobile) {
                        align-items: center;
                        text-align: center;
                    }

                    .date_txt {
                        @include font-medium;
                        color: $orange;
                        text-transform: uppercase;
                        line-height: 115%;
                    }

                    .loc_txt {
                        line-height: 115%;
                        text-transform: uppercase;
                        font-size: 14px;
                        color: #7A7A7A;
                    }
                }
            }

            .img_main {
                padding-top: 48px;
                padding-bottom: 60px;
                display: flex;
                justify-content: center;
                @include break(mobile) {
                    padding-bottom: 20px;
                }

                img {
                    max-width: 100%;
                    height: auto;
                }
            }

            .title, .btn_wrap {
                padding-top: 40px;
            }
            .txt {
                margin-top: 20px;
            }
            .img_container, .iframe_container {
                padding-top: 60px;
                display: flex;
                justify-content: center;

                img, iframe {
                    max-width: 100%;
                }

                img {
                    height: auto;
                }
            }
        }
    }
}