.module-08-cta-team {

    .ticket {
        width: 90%;
        max-width: 920px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 400px;
        position: relative;
        overflow: hidden;

        .line {
            height: 1px;
            width: 100%;
            background-color: $orange;
            position: absolute;
            left: 0;
            right: 0;
            @include break(mobile) {
                left: auto;
                right: auto;
                top: 0;
                bottom: 0;
                height: 100%;
                width: 1px;
            }

            &1 {
                top: 32px;
                @include break(mobile) {
                    top: 0;
                    left: 15px;
                }
            } 
            &2 {
                bottom: 32px;
                @include break(mobile) {
                    bottom: 0;
                    right: 15px;
                }
            } 
        }

        .circle {
            position: absolute;
            background-color: $tan;
            width: 194px;
            height: 194px;
            border-radius: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            @include break(mobile) {
                width: 170px;
                height: 170px;
                left: 50%;
            }
            &1 {
                left: 0;
                @include break(mobile) {
                    top: 0;
                    left: 50%;
                } 
            } 
            &2 {
                right: 0;
                transform: translate(50%,-50%);
                @include break(mobile) {
                    top: auto;
                    bottom: 0;
                    right: auto;
                    transform: translate(-50%,50%);
                } 
            } 
        }

        .content {
            padding-top: 80px;
            padding-bottom: 80px;
            width: 60%;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            @include break(mobile) {
                padding-top: 120px;
                padding-bottom: 120px;
                width: 80%;
            }
            .txt {
                padding-top: 24px;
            }
            
            .btn {
                margin-top: 40px;
                @include break(tablet) {
                    margin-top: 24px;
                }
            }
        }
    }

}