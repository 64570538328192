.module-14-faq {

    .faq_wrapper {
        width: 100%;
        max-width: 920px;
        margin-left: auto;
        margin-right: auto;
        .faq {
            border-bottom: 1px solid $dark;
            margin-bottom: 26px;
            padding-bottom: 26px;
            .question {
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                
                h4 {
                    width: 100%;
                    flex-shrink: 1;
                    line-height: 1.5em;
                    transition: all 0.4s;
                }

                .arrow {
                    flex-shrink: 0;
                    margin-left: 40px;
                    margin-top: -6px;
                    height: 31px;
                    transition: all 0.4s;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg path {
                        transition: all 0.4s;
                    }
                }

                &:hover {
                    color: $orange;

                    svg path {
                        fill: $orange;
                    }
                }
            }

            .answer {
                display: none;
                overflow: hidden;
                .answer_inner {
                    padding-top: 18px;
                    margin-right: 5%;
                    width: 90%;
                    max-width: 800px;
                    @include break(tablet) {
                        width: 100%;
                    }
                }
            }

            &.open {
                .arrow {
                    transform: rotate(180deg);
                }
            }
        }
    }

}