html{
  overflow-x: hidden;
  overflow-y: scroll;
  &.overflow{
    overflow: hidden;
  }
  background-color: $orange;
  color: $dark;
  &::-webkit-scrollbar { width: 0 !important };
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  min-height: -webkit-fill-available;
}

body {
  &.overflow{
    overflow: hidden;
  }
  background-color: $tan;
  color: $dark;
  font-size: $font-size;
  line-height: 150%;
  @include break(mobile){
    font-size: 16px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  @include font-regular;
  font-style: normal;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.loader_container {
    z-index: 9999;
    position:fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $tan;
  .loader{
    @include absolute-center(center);
    width: 345px;
    height: 405px;
    border-radius: 100%;
    overflow: hidden;
  }
  .loader_frame {
    border-radius: 100%;
    border: 1px solid $orange;
    width: 345px;
    height: 405px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
  .loader_dog {
    position: relative;
  }
  .loader_dog_body {
    margin-top: 50px;
    margin-left: 34px;
  }
  .loader_dog_hat {
    position: absolute;
    top: 34px;
    left: 107px;
    z-index: 10;
  }
}

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: $container;
}
.container_big {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.flex {
  display: flex;
  justify-content: space-between;
  >* {
    width: 50%;
  }
}

.mobile {
  display: none;
  @include break(mobile) {
    display: block;
  }
}
.desktop{
  @include break(mobile) {
    display: none;
  }
}

a {
  color: inherit;
  transition: all 0.3s;

  &:hover {
    color: $primary;
  }
}

h1, h2, h3, h4 {
  a {
    color: inherit;
    text-decoration: none;
  }
}

.ghost {
  height: 0!important;
  padding: 0!important;
  margin: 0!important;
  border: none!important;
}

.breadcrumb {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-bottom: 30px;
  @include break(mobile) {
    padding-bottom: 20px;
  }
  .space {
    padding-left: 5px;
    padding-right: 5px;
  }
  p, a {
    text-decoration: none;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}


h1, .title-h1 {
  @include font-titre;
  font-size: 4.25em;
  line-height: 108%;
  margin: 0;
  padding: 0;
  @include break(mobile) {
    font-size: 3.125em;
  }
}

h2, .title-h2 {
  @include font-titre;
  font-size: 2.5em;
  line-height: 108%;
  margin: 0;
  padding: 0;
}
h3, .title-h3 {
  @include font-titre;
  font-size: 1.5em;
  line-height: 108%;
  margin: 0;
  padding: 0;
}
h4, .title-h4 {
  @include font-titre;
  font-size: 1.2em;
  line-height: 108%;
  margin: 0;
  padding: 0;
}
h5, .title-h5 {
  @include font-semibold;
  font-size: 1.2em;
  line-height: 150%;
  margin: 0;
}
h6, .title-h6 {
  @include font-semibold;
  font-size: 1em;
  line-height: 150%;
  margin: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}


// Background
.bg_white {
  background-color: $white;
}
.bg_dark {
  background-color: $dark;
}
.bg_light {
  background-color: $tan;
}
.bg_grey {
  background-color: $grey;
}
.bg_orange {
  background-color: $orange;
}

.bg_pattern {
  background-color: $grey;
  background-image: url(../img/bg-pattern.svg);
}
.bg-dark_pattern {
  background-color: $dark;
  background-image: url(../img/bg-pattern_light.svg);
}

.txt-orange {
  color: $orange;
}

// Boutons / Link
.btn {
  position: relative;
  @include font-titre;
  color: $tan;
  font-size: 18px;
  display: flex;
  cursor: pointer;
  transition: all 0.4s;
  text-decoration: none;

  .btn_txt {
    display: flex;
    background-color: $orange;
    padding: 11px 15px;
    transition: background-color 0.4s;
    align-items: center;

    svg {
      margin-left: 10px;
      path {
        transition: all 0.4s;
      }
    }
  }

  .border {
    height: auto;
    path {
      transition: all 0.4s;
    }
  }
  .border1 {
    margin-right: -1px;
  }
  .border2 {
    transform: rotate(180deg);
    margin-left: -1px;
  }

  &:hover {
    color: $orange;
    .btn_txt {
      background-color: $white;
      svg path {
        fill: $orange;
      }
    }
    .border path {
      fill: $white;
    }
  }


}

a.link {
  color: $primary;
  display: flex;
  font-size: 14px;
  text-transform: uppercase;
  align-items: center;
  transition: all 0.3s;
  svg {
    margin-left: 12px;
    path {
      transition: all 0.3s;
    }
  }
}


// Title top
.title_top {
  padding-right: 45%;
  padding-bottom: $pad-large;
  @include break(tablet){
    padding-right: 0%;
    padding-bottom: $pad-large_mobile;
  }

  .txt {
    padding-top: $pad-xsmall;
  }
}

.title_top-center {
  text-align: center;
  padding-bottom: 60px;
  @include break(tablet){
    padding-right: 0%;
    padding-bottom: 40px;
  }

  .txt {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: $pad-xxsmall;
  }
}


// Padding
.sec-pad-top_big {
  padding-top: $pad-xlarge;
  @include break(tablet){
    padding-top: 40px;
  }
}
.sec-pad-top_normal {
  padding-top: $pad-large;
  @include break(tablet){
    padding-top: 30px;
  }
}
.sec-pad-top_small {
  padding-top: $pad-med;
  @include break(tablet){
    padding-top: 20px;
  }
}

.sec-pad-top_none {
  padding-top: 0;
  @include break(tablet){
    padding-top: 0px;
  }
}

.sec-pad-bottom_big {
  padding-bottom: $pad-xlarge;
  @include break(tablet){
    padding-bottom: 40px;
  }
}
.sec-pad-bottom_normal {
  padding-bottom: $pad-large;
  @include break(tablet){
    padding-bottom: 30px;
  }
}
.sec-pad-bottom_small {
  padding-bottom: $pad-med;
  @include break(tablet){
    padding-bottom: 20px;
  }
}
.sec-pad-bottom_none {
  padding-top: 0;
  @include break(tablet){
    padding-top: 0px;
  }
}




// POPUP
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 999999;
  align-items: center;
  justify-content: center;

  .inner {
    position: absolute;
    width: 80%;
    height: 80vh;
    transform-origin: center;
    display: flex;
    align-items: center;
    @include absolute-center(center);
    z-index: 2;
    pointer-events: none;
  }

  .video_wrap {
    pointer-events: all;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    iframe, video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 2;
    display: flex;
    align-items: center;
    transition: all 0.4s;
    span {
      font-style: italic;
    }

    svg path {
      transition: all 0.4s;
    }

    &:hover {
      color: $primary;
      svg path {
        fill: $primary;
        stroke: $primary;
      }
    }
  }

  .bg {
    background: rgba($dark, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}


// POPUP MAKER PLUGIN

.pum.pum-theme-787, .pum.pum-theme-1197{

  .pum-title.popmake-title {
    display: none;
  }
  .pum-container.pum-responsive.pum-responsive-medium {
      padding: 0!important;
      overflow: hidden;
      min-height: 70vh;
      background-color: #fff;
      border-radius: 4px;
      max-width: 920px!important;
      @include break(tablet) {
        min-height: 0;
      }
  }
  .pum-content{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 1em;
    color: $black;
    @include break(tablet) {
      position: relative;
    }

    & + .pum-close, .pum-theme-default-theme .pum-content + .pum-close {
        position: absolute;
        width: 24px;
        height: 24px;
        left: auto;
        right: 14px;
        bottom: auto;
        top: 14px;
        padding: 0px;
        color: rgba(0,0,0,0);
        font-family: inherit;
        font-weight: 400;
        font-size: 1px;
        line-height: 1px;
        border: none;
        border-radius: 0px;
        box-shadow: none;
        text-shadow: none;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%232F2F2F%22%20fill-rule%3D%22evenodd%22%20d%3D%22M10.303%2012%204.432%206.13l1.697-1.697L12%2010.303l5.872-5.871%201.697%201.697L13.697%2012l5.872%205.872-1.697%201.697L12%2013.697%206.13%2019.57l-1.697-1.697L10.303%2012Z%22%20clip-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
        background-position: center;
        background-size: contain;
        transition: all 0.4s;

        &:hover {
          transform: scale(1.05);
        }
    }
  }

  .container_border {
    border: 1px solid $orange;
    position: absolute;
    border-radius: 4px;
    z-index: 4;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    pointer-events: none;
  }

  .pom_inner-container {
    display: flex;
    height: 100%;
    @include break(tablet) {
      flex-direction: column;
    }
    .left {
      width: 50%;
      position: relative;
      @include break(tablet) {
        display: none;
      }
      .img_container {
        position: absolute;
        top: -1px;
        left: -1px;
        right: 0;
        bottom: -1px;
        img {
          @include bg-img;
        }
      }
    }
    .right {
      width: calc(50% - 4px);
      text-align: center;
      padding: 5% 4%;
      overflow-y: scroll;
      overflow-x: hidden;
      margin-top: 4px;
      margin-bottom: 4px;
      right: 4px;
      @include break(tablet) {
        width: 100%;
        padding: 16% 5%;
      }

      .logo {
        width: 120px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 14%;
        @include break(tablet) {
          margin-bottom: 12%;
        }
        img {
          width: 100%!important;
          height: auto!important;
        }
      }

      h1,h2,h3,h4,h5,h6 {
        margin-bottom: 15px;
      }

      .txt {
        line-height: 150%;
      }

      .btn_container {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 24px;
        .btn {
          opacity: 1!important;
          br {
            display: none;
          }
          &:hover {
            color: $orange!important;
            .btn_txt {
              background-color: $tan!important;
            }
            .border path {
              fill: $tan;
            }
          }
        }
      }

    }
  }

  @media only screen and (min-width: 1024px) {
    .pum-container.pum-responsive.pum-responsive-medium {
        margin-left: -35%;
        width: 70%;
    }
  }
}


.pum.pum-theme-789, .pum.pum-theme-726, .pum.pum-theme-1200 {

  .pum-title.popmake-title {
    display: none;
  }

  .pum-container.pum-responsive.pum-responsive-medium {
    padding: 0!important;
    overflow: hidden;
    background-color: #fff;
    border-radius: 4px;
    max-width: 450px!important;
  }

  .pum-content{
    font-size: 1em;
    color: $black;

    & + .pum-close, .pum-theme-default-theme .pum-content + .pum-close {
        position: absolute;
        width: 24px;
        height: 24px;
        left: auto;
        right: 14px;
        bottom: auto;
        top: 14px;
        padding: 0px;
        color: rgba(0,0,0,0);
        font-family: inherit;
        font-weight: 400;
        font-size: 1px;
        line-height: 1px;
        border: none;
        border-radius: 0px;
        box-shadow: none;
        text-shadow: none;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%232F2F2F%22%20fill-rule%3D%22evenodd%22%20d%3D%22M10.303%2012%204.432%206.13l1.697-1.697L12%2010.303l5.872-5.871%201.697%201.697L13.697%2012l5.872%205.872-1.697%201.697L12%2013.697%206.13%2019.57l-1.697-1.697L10.303%2012Z%22%20clip-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
        background-position: center;
        background-size: contain;
        transition: all 0.4s;

        &:hover {
          transform: scale(1.05);
        }
    }
  }

  .container_border {
    border: 1px solid $orange;
    position: absolute;
    border-radius: 4px;
    z-index: 4;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    pointer-events: none;
  }

  .txt-container {
    padding: 12% 5%;
    text-align: center;
    .logo {
      width: 120px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 14%;
      @include break(tablet) {
        margin-bottom: 12%;
      }
      img {
        width: 100%!important;
        height: auto!important;
      }
    }

    h1,h2,h3,h4,h5,h6 {
      margin-bottom: 15px;
    }

    .txt {
      line-height: 150%;
    }

    .btn_container {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 24px;
      .btn {
        opacity: 1!important;
        br {
          display: none;
        }
        &:hover {
          color: $orange!important;
          .btn_txt {
            background-color: $tan!important;
          }
          .border path {
            fill: $tan;
          }
        }
      }
    }
  }
}