.module-12-gallery {
    overflow: hidden;
    .gallery {
        position: relative;

        .img_row {
            display: flex;
            justify-content: space-between;
            position: relative;
            &:first-of-type {
                margin-top: -15%;
            }

            .img_container {
                max-width: 450px;
                width: 35%;
                height: auto;
                transform: translateY(100%);

                &:last-of-type {
                    margin-top: 100px;
                }
                img {
                    width: 100%;
                    height: auto;
                }
            }

            .tavern {
                position: absolute;
                z-index: 3;
                mix-blend-mode: multiply;
                width: 165%;
                top: 65%;
                left: 17%;
                svg {
                    width: 100%;
                }
            }

            &:nth-child(3) {
                .tavern {
                    top: 30%;
                    left: auto;
                    right: 30%;
                }
            }
        }

        .img_row-center {
            margin-top: 2.5%;
            .img_container {
                max-width: 560px;
                width: 55%;
                margin-left: 30%;
                height: auto;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}