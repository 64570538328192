.template-single-locations {

    .hero_container {
        position: relative;

        .anime_icon1 {
            position: absolute;
            width: 165px;
            height: 165px;
            bottom: 0px;
            right: 40%;
            @include break(tablet) {
                display: none;
            }
        }

        .content {
            position: relative;
            z-index: 2;
        }
    }

    .title_info {
        padding-bottom: $pad-med;
    }

    section.info {
        position: relative;
        .anime_icon2 {
            position: absolute;
            width: 145px;
            height: 145px;
            top: 140px;
            right: 15%;
            @include break(tablet) {
                display: none;
            }
        }
    }

    .info_container {
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 2;
        @include break(tablet) {
            flex-direction: column;
        }

        .col {
            min-width: 255px;
            width: 24%;
            @include break(tablet) {
                width: 100%;
                margin-bottom: 30px;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .title {
                display: flex;
                padding-bottom: 15px;
                @include break(tablet) {
                    padding-bottom: 5px;
                }
                .state {
                    color: $orange;
                    font-size: 13px;
                    text-transform: uppercase;
                    margin-left: 10px;
                }
            }

            .hours_day {
                display: flex;
                justify-content: space-between;
                align-items: center;
                >div {
                    display: flex;
                    text-transform: capitalize;
                }

                svg {
                    display: none;
                }

                &.active {
                    color: $orange;
                    svg {
                        display: block;
                    }
                }
            }

            .contact {
                a  {
                    display: block;
                    margin-bottom: 8px;
                    color: $orange;
                    @include font-semibold;
                    &:hover {
                        color: $dark; 
                    }

                    &.link_ghost {
                        @include font-text;
                        color: $dark;
                        text-decoration: none;
                        &:hover {
                            color: $orange; 
                        }
                    }
                }
                
            }
        }


    }

    .menu {
        padding-top: $pad-med;
        padding-bottom: $pad-med;
        .title_info {
            display: flex;
            align-items: center;
            padding-bottom: 60px;
            @include break(tablet) {
                align-items: flex-start;
                flex-direction: column;
                padding-bottom: 35px;
            }
            
            .filter_wrap {
                display: flex;
                flex-wrap: wrap;
                margin-left: 46px;
                @include break(tablet) {
                    margin-left: 0px;
                    margin-top: 25px;
                }

                .filter {
                    padding: 1px 12px;
                    border: 1px solid $orange;
                    color: $orange;
                    border-radius: 4px;
                    border: 1px solid $orange;
                    margin-right: 12px;
                    cursor: pointer;
                    transition: color 0.4s, background-color 0.4s;
                    @include break(tablet) {
                        margin-bottom: 15px;
                    }

                    &.active, &:hover {
                        background-color: $orange;
                        color: $tan;
                    }

                    &.active {
                        pointer-events: none;
                    }
                }
            }
        }

        .menu_container {
            position: relative;
            padding: 60px 130px;
            @include break(small-screen) {
                padding: 50px 7%; 
            }
            @include break(tablet) {
                padding: 40px 5%; 
            }
            .frame_top_desk {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 30px;
                width: 100%;
                @include break(mobile) {
                   display: none;
                }
            }
            .frame_top_mobile {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 30px;
                width: 100%;
                @include break(mobile) {
                   display: block;
                }
            }
            .frame_bottom_desk {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 30px;
                width: 100%;
                @include break(mobile) {
                   display: none;
                }
            }
            .frame_bottom_mobile {
                display: none;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 30px;
                width: 100%;
                @include break(mobile) {
                    display: block;
                }
            }
            .side1 {
                width: 1px;
                background-color: $orange;
                position: absolute;
                bottom: 30px;
                left: 0;
                right: auto;
                top: 30px;
            }
            .side2 {
                width: 1px;
                background-color: $orange;
                position: absolute;
                bottom: 30px;
                right: 0;
                left: auto;
                top: 30px;
            }

            .menu_sec {
                display: none;
                &:first-of-type {
                    display: block;
                }
                .menu-title {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    h2 {
                        margin-left: 100px;
                        margin-right: 100px;
                        @include font-semibold;
                        color: $orange;
                        font-size: 6.4em;
                        flex-shrink: 1;
                        text-align: center;
                        @include break(tablet) {
                            font-size: 3.25em;
                            margin-left: 0px;
                            margin-right: 0px;
                        }
                    }

                    svg {
                        flex-shrink: 0;
                        @include break(tablet) {
                            display: none;
                        }
                    }
                }
                .top {
                    margin-bottom: 80px;
                    @include break(tablet) {
                        margin-bottom: 50px;
                    }

                    .txt {
                        text-align: center;
                        @include font-semibold;
                        font-size: 1.2em;
                        margin-top: 30px;
                        @include break(tablet) {
                            margin-top: 15px;
                        }
                    }
                }

                .sub {
                    padding-bottom: 80px;
                    @include break(tablet) {
                        padding-bottom: 0px;
                    }
                    &:last-of-type {
                        padding-bottom: 0;
                        @include break(tablet) {
                            padding-bottom: 0px;
                        }
                    }

                    .title {
                        padding-bottom: 25px;
                        border-bottom: 1px solid $orange;
                        margin-bottom: 50px;
                        display: flex;
                        justify-content: space-between;
                        pointer-events: none;
                        transition: all 0.4s;
                        @include break(tablet) {
                            pointer-events: all;
                            padding-bottom: 20px;
                            margin-bottom: 30px;
                            border-bottom: 1px solid transparent;
                        }
                        .title-h2 {
                            color: $orange;
                            transition: all 0.4s;
                            @include break(tablet) {
                                font-size: 2em;
                                color: $dark;
                            }
                        }
                        .desc {
                            @include font-semibold;
                            font-size: 1.15em;
                            padding-top: 7px;
                            @include break(tablet) {
                                padding-top: 5px;
                                font-size: 1.05em;
                            }
                        }
                        .arrow {
                            display: none;
                            flex-shrink: 0;
                            margin-left: 10px;
                            svg {
                                transition: all 0.4s;
                            }
                            @include break(tablet) {
                                display: block;
                            }
                        }
                    }

                    .items_wrapper {
                        overflow: hidden;
                        @include break(tablet) {
                            display: none;
                        }
                    }

                    &.open {
                        .title {
                            border-bottom: 1px solid $orange;
                            .title-h2 {
                                color: $orange;
                            }
                            .arrow svg {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

                .item {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    padding-bottom: 30px;
                    &:last-of-type {
                        padding-bottom: 0;
                        @include break(tablet) {
                            padding-bottom: 50px;
                        }
                    }
                    @include break(tablet) {
                        flex-direction: column;
                    }

                    .info {
                        width: 50%;
                        .desc {
                            padding-top: 5px;
                            @include break(tablet) {
                                padding-top: 0px;
                                font-size: 14px;
                            }
                        }
                        @include break(tablet) {
                            width: 100%;
                        }
                    }
                    .price {
                        @include font-semibold;
                        width: 30%;
                        margin-left: 40px;
                        min-width: 320px;
                        display: flex;
                        justify-content: flex-end;
                        text-align: right;
                        flex-wrap: wrap;
                        @include break(small-screen) {
                            font-size: 16px;
                        }
                        @include break(tablet) {
                            font-size: 1em;
                            width: 100%; 
                            margin-left: 0px;
                            min-width: 0px;
                            text-align: left;
                            justify-content: flex-start;
                            padding-top: 7px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

}