.module-10-cta-box {

    .container {
        display: flex;
        padding-top: 100px;
        padding-bottom: 100px;
        justify-content: space-between;
        position: relative;
        @include break(tablet) {
            padding-top: 60px;
            padding-bottom: 60px;
            flex-direction: column;
        }
        .box {
            width: 40%;
            color: $white;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            @include break(tablet) {
                width: 100%;
            }
            .txt {
                padding-top: 20px;
            }

            .bottom {
                margin-top: 40px;
                height: 75px;
                display: flex;
                flex-direction: column;
                align-items: center;
                @include break(tablet) {
                    margin-top: 24px;
                    height: auto;
                }

                small {
                    display: block;
                    padding-top: 5px;
                    color: #959595;
                    font-size: 13px;
                }
            }
        }

        .line {
            width: 1px;
            background-color: #BEBEBE;
            @include break(tablet) {
                width: 100%;
                height: 1px;
                margin-top: 60px;
                margin-bottom: 60px;
            }
        }
    }

}