.module-16-cta_location {
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include break(tablet) {
            flex-direction: column-reverse;
        }

        .content {
            width: 45%;
            @include break(tablet) {
                width: 100%;
            }
            .txt {
                padding-top: 24px;
            }
            .btn {
                margin-top: 40px;
                @include break(tablet) {
                    margin-top: 24px;
                }
            }
        }

        .img_container {
            width: 255px;
            margin-right: 5%;
            position: relative;
            @include break(tablet) {
                margin-right: 0;
                width: 75%;
                margin-bottom: 30px;
            }
        }

    }
}