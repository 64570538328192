.module-04-locations {
    overflow: hidden;
    .location_container {
        display: flex;
        align-items: center;
        justify-content: center;
        @include break(mobile) {
            justify-content: flex-start;
        }
        .location {
            overflow: hidden;
            display: flex;
            justify-content: center;
            @include break(mobile) {
                justify-content: flex-start;
                overflow-x: scroll;
                overflow-y: hidden;
                overflow: -moz-scrollbars-none;
                scroll-behavior: smooth;
                scroll-snap-type: x mandatory;
                &::-webkit-scrollbar { width: 0 !important };
                width: 100%;


                .card_loc {
                    scroll-snap-align: start;
                    scroll-margin:5vh;
                    margin-left: 0.5vh;

                    &:first-of-type {
                        margin-left: 10vh;
                    }
                    &:last-of-type {
                        margin-right: 10vh;
                    }
                }
            }   
        }

        .card_loc {
            margin-left: 20px;
            margin-right: 20px;
            flex-shrink: 0;
            width: 358px;
            height: 522px;
            position: relative;
            border-radius: 4px;
            overflow: hidden;
            transition: none;
            @include break(mobile) {
                width: 305px;
                height: 445px;
            }

            .bg {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $grey;
                transition: all 0.6s;
                img {
                    @include bg-img;
                    z-index: 0;
                }
                .filter {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(0, 0, 0, 0.30);
                    z-index: 1;  
                }
                svg {
                    @include absolute-center(center);
                    top: 47%;
                }

            }

            .content {
                border: 1px solid $orange;
                position: absolute;
                top: 10px;
                left: 10px;
                right: 10px;
                bottom: 10px;
                border-radius: 4px;
                z-index: 2;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                padding: 40px 20px 35px 20px;
                @include break(mobile) {
                    padding: 35px 15px 30px 15px;
                }

                .logo {
                    img, svg {
                        width: 130px;
                        @include break(mobile) {
                            width: 102px!important;
                        }
                    }
                    svg path, svg polygon, svg rect {
                        fill: $white;
                    }

                    &.logo-dark {
                        svg path, svg polygon, svg rect {
                           fill: $dark;
                        }
                        img {
                            filter: grayscale(1) brightness(0);
                        }
                    }
                }

                .txt {
                    color: $white;
                    text-align: center;

                    h6 {
                        text-transform: uppercase;
                        line-height: 108%;
                    }
                    p {
                        font-size: 13px;
                        text-transform: uppercase;
                    }
                    .cta {
                        display: inline-flex;
                        color: $orange;
                        padding-top: 12px;
                        @include font-titre;
                        line-height: 105%;
                        border-bottom: 1px solid $orange;
                    }
                    .bold {
                        @include font-medium;
                    }
                    .opacity {
                        opacity: 0.5;
                    }
                    &.dark {
                        color: $dark;
                    }
                }
            }

            &:hover {
                .bg {
                    transform: scale(1.035);
                }
            }

            &.soon {
                .content {
                    border: none;
                }
                .bg svg {
                    @include break(mobile) {
                        width: 150px!important;
                        height: auto!important;
                    }
                }
            }
        }

        .arrow {
            cursor: pointer;
            transition: all 0.4s;
            &:hover {
                transform: scale(1.1);
            }

            @include break(tablet) {
                display: none;
            }
        }
        .arrow_left {
            margin-right: 2.5%;
        }
        .arrow_right {
            margin-left: 2.5%;
        }
        .location.slider {
            height: 522px;
            position: relative;
            overflow: visible;
            width: 80%;
            flex-shrink: 1;
            max-width: $container;
            @include break(small-screen) {
                height: 445px;
            }
            @include break(tablet) {
                width: 90%;
                margin-left: 10%;
            }
            
            .card_loc {
                width: 32%;
                max-width: 358px;
                position: absolute;
                top: 0;
                left: auto;
                display: none;
                margin: 0;
                @include break(small-screen) {
                    width: 32%;
                    max-width: 305px;
                    height: 445px;

                    .content {
                        padding: 35px 15px 30px 15px;
                    }
                    .logo {
                        img, svg {
                            width: 102px!important;
                        }
                    }
                }
                @include break(tablet) {
                    width: 305px;
                }

                &:first-child {
                    left: 0;
                    display: block;
                }
                &:nth-child(2) {
                    left: 50%;
                    transform: translateX(-50%);
                    display: block;
                    @include break(tablet) {
                        left: 325px;
                        transform: none;
                    }
                }
                &:nth-child(3) {
                    right: 0;
                    display: block;
                    @include break(tablet) {
                        right: auto;
                        left: 650px;
                    }
                }
            }
        }
    }
}