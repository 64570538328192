.module-02-2col {

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include break(tablet) {
            flex-direction: column-reverse;
        }

        .content {
            width: 51%;
            @include break(tablet) {
                width: 100%;
            }

            .txt {
                padding-top: 24px;
            }
            
            .btn {
                margin-top: 40px;
                @include break(tablet) {
                    margin-top: 24px;
                }
            }
        }

        .img_container {
            width: 39%;
            padding-bottom: 28.8%;
            position: relative;
            @include break(tablet) {
                width: 100%;
                margin-bottom: 40px;
                padding-bottom: 74%;
            }
            img {
                @include bg-img;
            }
        }

        &.img_big {
            .content {
                width: 45%;
                @include break(tablet) {
                    width: 100%;
                }
            }
            .img_container {
                width: 49%;
                padding-bottom: 40.9%;
                @include break(tablet) {
                    width: 100%;
                    padding-bottom: 82%;
                }
            }
        }
    }

    &.layout-txt-right .container {
        flex-direction: row-reverse;
        @include break(tablet) {
            flex-direction: column-reverse;
        }
    }



}