.template-basic {

    .hero-simple {
        text-align: center;
        padding-top: 200px;
        padding-bottom: 80px;

        h1 {
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .content {
        padding-bottom: 80px;
        p  {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        h2, h3 {
            margin-top: 60px;
        }
    }

}