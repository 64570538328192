.module-07-cta-find {
    
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            padding-bottom: 24px;
            font-size: 1.3em;
            text-transform: uppercase;
        }

        .title {
            svg {
                width: 90vw;
                max-width: 820px;
            }
            .big_txt {
                color: $orange;
                @include font-semibold;
                font-size: 190px;
                @include break(small-screen) {
                    font-size: 14vw;
                }
                @include break(mobile) {
                    font-size: 18vw;
                }
            }
        }

        .btn {
            margin-top: 60px;
            @include break(mobile) {
                margin-top: 40px;
            }
        }
    }
}