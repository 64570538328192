.module-09-cta-gift {
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include break(tablet) {
            flex-direction: column-reverse;
        }

        .content {
            width: 45%;
            @include break(tablet) {
                width: 100%;
            }
            
            .btn {
                margin-top: 40px;
                @include break(tablet) {
                    margin-top: 24px;
                }
            }
        }

        .img_container {
            width: 50%;
            padding-bottom: 41%;
            position: relative;
            @include break(tablet) {
                width: 100%;
                margin-bottom: 40px;
                padding-bottom: 74%;
            }
            img {
                @include bg-img;
            }
        }

    }
}