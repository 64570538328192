@import 'partials/_mixin';
@import 'partials/_function';

@import "./base/variable";
@import "./base/wordpress";
@import './base/breakpoint';
@import "./base/reset.scss";
@import './base/normalize';
@import './base/easing';
@import './base/fonts';
@import './base/layout';

@import './common/header';
@import './common/footer';
@import "./common/general";
@import "./common/anim-class";

@import "./modules/module-01-hero";
@import "./modules/module-02-2col";
@import "./modules/module-03-content_cards";
@import "./modules/module-04-locations";
@import "./modules/module-05-events";
@import "./modules/module-06-banner";
@import "./modules/module-07-cta-find";
@import "./modules/module-08-cta-team";
@import "./modules/module-09-cta-gift";
@import "./modules/module-10-cta-box";
@import "./modules/module-11-cta-banner";
@import "./modules/module-12-gallery";
@import "./modules/module-13-testimonial";
@import "./modules/module-14-faq";
@import "./modules/module-15-group-booking";
@import "./modules/module-16-cta_location";

@import './pages/template-contact';
@import './pages/template-archive-locations';
@import './pages/template-archive-events-promotions';
@import './pages/template-locations';
@import './pages/template-single-events-promotions';
@import './pages/template-basic';
@import './pages/quatrecentquatre';

