.template-archive-locations {

    .hero {
        padding-top: 120px;
        padding-bottom: 105px;
        @include break (mobile) {
            padding-top: 105px;
            padding-bottom: 60px;
        } 
        .container {
            padding-top: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include break (mobile) {
                padding-top: 40px;
            }  
            h1 {
                width: 450px;
                margin-left: 10px;
                margin-right: 10px;
                text-align: center;
                @include break (tablet) {
                    width: 100%;
                    margin-left: 0px;
                    margin-right: 0px;
                }
            }
            svg {
                @include break (tablet) {
                    display: none;
                }
            }
        }
    }

    .filter_container {
        .select_wrap {
            position: relative;
            width: fit-content;
            svg {
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        select {
            appearance: none;
            border: 1px solid $orange;
            background-color: transparent;
            outline: none;
            padding: 4px 40px 3px 12px;
            border-radius: 4px;
        }
    }

    .archive {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: -20px;
        margin-right: -20px;
        margin-top: 40px;
        @include break(mobile) {
            margin-left: 0px;
            margin-right: 0px;
        }

        .card_loc {
            margin-left: 20px;
            margin-right: 20px;
            flex-shrink: 0;
            width: 358px;
            height: 522px;
            position: relative;
            border-radius: 4px;
            overflow: hidden;
            margin-bottom: 40px;
            transition: none;
            display: none;
            @include break(mobile) {
                width: 305px;
                height: 445px;
                margin-left: 0px;
                margin-right: 0px;
            }

            .bg {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $grey;
                transition: all 0.6s;
                img {
                    @include bg-img;
                    z-index: 0;
                }
                .filter {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(0, 0, 0, 0.30);
                    z-index: 1;  
                }
                svg {
                    @include absolute-center(center);
                    top: 47%;
                    @include break(mobile) {
                        width: 155px;
                    }
                }

            }

            .content {
                border: 1px solid $orange;
                position: absolute;
                top: 10px;
                left: 10px;
                right: 10px;
                bottom: 10px;
                border-radius: 4px;
                z-index: 2;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                padding: 40px 20px 35px 20px;

                .logo {
                    img, svg {
                        width: 130px;
                        @include break(mobile) {
                            width: 102px!important;
                        }
                    }
                    svg path, svg polygon, svg rect {
                        fill: $white;
                    }

                    &.logo-dark {
                        svg path, svg polygon, svg rect {
                           fill: $dark;
                        }
                        img {
                            filter: grayscale(1) brightness(0);
                        }
                    }
                }

                .txt {
                    color: $white;
                    text-align: center;

                    h6 {
                        text-transform: uppercase;
                        line-height: 108%;
                    }
                    p {
                        font-size: 13px;
                        text-transform: uppercase;
                    }
                    .cta {
                        display: inline-flex;
                        color: $orange;
                        padding-top: 12px;
                        @include font-titre;
                        line-height: 105%;
                        border-bottom: 1px solid $orange;
                    }
                    .bold {
                        @include font-medium;
                    }
                    .opacity {
                        opacity: 0.5;
                    }
                    &.dark {
                        color: $dark;
                    }
                }
            }

            &:hover {
                .bg {
                    transform: scale(1.035);
                }
            }

            &.ghost {
                margin-left: 20px!important;
                margin-right: 20px!important;
                @include break(mobile) {
                    display: none;
                }
            }

            &.visible {
                display: block;
            }
        }
    }

}