header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  pointer-events: none;


  .nav-top {
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding-top: 16px;
    padding-bottom: 16px;
    @include break (mobile) {
      padding-top: 13px;
      padding-bottom: 13px;
    } 

    .burger_wrap {
      width: 300px;
      @include break(tablet) {
        width: 33px;
      }
      .burger {
        pointer-events: all;
        width: 33px;
        height: 33px;
        position: relative;
        cursor: pointer;
  
        div {
          transform-origin: center center;
          background-color: $orange;
          height: 3px;
          width: 27px;
          @include absolute-center(center);
          transition: all 0.4s;
          &:first-of-type {
            top: 42.5%;
          }
          &:last-of-type {
            top: 62.5%;
          }
        }
  
        &:hover {
          div {
            background-color: $orange;
          }
        }
      }
    }

    .logo {
      a {
        pointer-events: all;
        display: flex;
        width: 156px;
        @include break(tablet) {
          width: 105px;
        }
        svg {
          width: 100%;
          height: auto;
          path {
            transition: all 0.4s;
            fill: $dark;
          }
        }
      }
      img {
        filter: brightness(0);
        transition: all 0.4s;
      }
    }

    .btn_wrap {
      width: 300px;
      display: flex;
      justify-content: flex-end;
      @include break(tablet) {
        width: 33px;
      }

      .nav_btn {
        pointer-events: all;
        background-color: $orange;
        padding: 4px 14px;
        border-radius: 50px;
        @include font-titre;
        font-size: 14px;
        align-items: center;
        color: $white;
        text-decoration: none;
        margin-left: 10px;
        line-height: 150%;
        font-size: 14px;
        transition: background-color 0.4s, color 0.4s;
        display: none;

        svg {
          margin-left: 5px;
          path {
            transition: all 0.4s;
          }
        }

        &:hover {
          background-color: $white;
          color: $orange;
          svg path {
            fill: $orange;
          }
        }
      }
    }

    &.color_white {
      .logo {
        svg path {
          fill: $white;
        }
        img {
          filter: brightness(1);
        }
      }  
    }
  }
  
  .location_btn {
    display: none;
    justify-content: space-between;
    padding-top: 10px;
    @include break(tablet) {
      display: flex;
    }

    .nav_btn {
      pointer-events: all;
      background-color: $orange;
      padding: 4px 14px;
      border-radius: 50px;
      @include font-titre;
      font-size: 14px;
      align-items: center;
      color: $white;
      text-decoration: none;
      line-height: 150%;
      font-size: 14px;
      transition: background-color 0.4s, color 0.4s;
      width: 45%;
      display: flex;
      justify-content: center;

      svg {
        margin-left: 5px;
        path {
          transition: all 0.4s;
        }
      }

      &:hover {
        background-color: $white;
        color: $orange;
        svg path {
          fill: $orange;
        }
      }
    }
  }

  .nav-menu {
      pointer-events: all;
      height: 100%;
      flex-shrink: 1;
      display: none;
      align-items: center;
      justify-content: space-between;
      padding-top: 60px;
      padding-bottom: 100px;
      @include break(tablet) {
        padding-top: 20px;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        overflow-y: scroll;
        overflow-x: hidden;
      }

      .left {
        display: flex;
        flex-direction: column;
        width: 47.5%;
        @include break(small-height) {
          padding-bottom: 30px;
        }
        @include break(tablet) {
          width: 100%;
        }

        a {
          @include font-titre;
          font-size: 3em;
          display: block;
          text-decoration: none;
          line-height: 100%;
          margin-top: 40px;
          opacity: 0;
          transition: color 0.4s;
          width: fit-content;
          @include break(small-height) {
            font-size: 2.5em;
            margin-top: 25px;
          }
          @include break(tablet) {
            font-size: 2.15em;
            margin-top: 25px;
          }
        }

        .social_container {
          display: flex;
          @include break(small-height) {
            padding-bottom: 20px;
          }
          @include break(tablet) {
            position: initial;
            justify-content: flex-start;
          }
          a {
            text-decoration: none;
            margin-right: 45px;
            opacity: 1;
            @include break(tablet) {
              margin-right: 45px;
              margin-left: 0;
            }
            svg {
                transition: all 0.4s;
                height: 23px;
                path {
                    fill: $dark;
                    transition: all 0.4s;
                }
            }

            &:hover {
                svg {
                    transform: scale(1.1);
                    path {
                        fill: $orange;
                    }
                }
            }
        }
        }
      }

      .right {
        position: relative;
        display: flex;
        width: 50.5%;
        @include break(small-height) {
          width: 40.5%;
        }
        @include break(tablet) {
          width: 100%;
          margin-top: 60px;
        }
        .dog {
          margin-bottom: -40px;
          width: 50%;
          max-width: 480px;
          position: relative;
          @include break(small-screen) {
            margin-bottom: 40px;
          }
          @include break(small-height) {
            margin-bottom: 0px;
          }
          @include break(small-height) {
            width: 60%;
          }
          @include break(tablet) {
            display: none;
          }
          .dog_hat {
            position: absolute;
            top: -5%;
            left: 25%;
            width: 43%;
          }
          .dog_body, .dog_body svg, .dog_hat svg {
            width: 100%;
          }

          .icon_container {
            position: absolute;
            width: 22%;
            padding-bottom: 22%;
            top: -8%;
            left: 36%;
          
            .icon {
              opacity: 0;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              position: absolute;
              svg {
                @include bg-img;
                object-fit: contain;
                width: 100%;
              }
            }
          }
        }
      }

      .btn_wrap-mobile {
        display: none;
        @include break(tablet) {
          margin-top: 80px;
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
  
        .nav_btn {
          background-color: $orange;
          padding: 4px 14px;
          border-radius: 50px;
          @include font-titre;
          font-size: 14px;
          display: flex;
          align-items: center;
          color: $white;
          text-decoration: none;
          line-height: 150%;
          font-size: 14px;
          svg {
            margin-left: 5px;
            path {
              transition: all 0.4s;
            }
          }
  
          &:hover {
            background-color: $white;
            color: $orange;
            svg path {
              fill: $orange;
            }
          }
        }
      }
  }

  &.open {
    .nav-top .burger_wrap .burger {
      div:first-of-type {
        top: 50%;
        transform: translate(-50%,-50%) rotate(45deg);
      }
      div:last-of-type {
        top: 50%;
        transform: translate(-50%,-50%) rotate(-45deg);
      }
    }
  }
}
