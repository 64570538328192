.module-01-hero {
    padding-top: 140px;
    @include break(mobile) {
        padding-top: 110px;
    }

    &.type-home {
        padding-bottom: 140px;
        height: 100vh;
        min-height: 700px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @include break(mobile) {
            padding-bottom: 40px; 
            min-height: 445px;
        }

        .content {
            position: relative;
            z-index: 2;

            .title-h1 {
                color: $white;
                padding-right: 35%;
                @include break(tablet) {
                    padding-right: 0;
                }
            }

            .btn {
                margin-top: 40px;
            }
        }

        .bg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
            z-index: 0;

            picture, video {
                z-index: 0;
                @include bg-img;
            }

            .filter {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                overflow: hidden;
                z-index: 2;
            }
        }
    }

    &.type-cook, &.type-basic {
        overflow: hidden;
        padding-bottom: 80px;
        @include break(mobile) {
            padding-bottom: 20px;
        }

        .hero_container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include break(tablet) {
                flex-direction: column-reverse;
            }

            .content {
                position: relative;
                z-index: 2;
                width: 40%;
                @include break(tablet) {
                    width: 100%;
                }

                .txt {
                    padding-top: 24px;
                }
                
                .btn {
                    margin-top: 40px;
                    @include break(tablet) {
                        margin-top: 24px;
                    }
                }
            }

            .img_container {
                width: 46%;
                padding-bottom: 49%;
                position: relative;
                @include break(tablet) {
                    width: 112%;
                    margin-left: -6%;
                    margin-right: -6%;
                    margin-bottom: 40px;
                    padding-bottom: 113%;
                }
                img {
                    @include bg-img;
                }

                .tavern {
                    position: absolute;
                    top: -8%;
                    left: 18%;
                    z-index: 3;
                    mix-blend-mode: multiply;
                    width: 404%;
                }
            }

        }
    }

    &.type-cook {
        .hero_container .img_container {
            overflow: visible;
        }
    }
}