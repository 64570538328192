.module-05-events {

    .events_container {
        display: flex;
        justify-content: center;
        @include break(tablet) {
            width: 280px;
            height: 370px;
            position: relative
        }

        .article_card {
            width: 395px;
            height: 530px;
            border-radius: 4px;
            overflow: hidden;
            position: relative;
            text-decoration: none;
            color: $dark;
            flex-grow: 0;
            flex-shrink: 0;
            transition: filter 0.4s;
            @include break(tablet) {
                width: 280px;
                height: 370px;
                position: absolute;
                top: 0;
                left: 0;
                transform-origin: bottom left;
            }

            .frame {
                position: absolute;
                top: 10px;
                left: 10px;
                right: 10px;
                bottom: 10px;
                z-index: 3;
                svg {
                    height: auto;
                }
                @include break(tablet) {
                    top: 7px;
                    left: 7px;
                    right: 7px;
                    bottom: 7px;
                    height: calc(100% - 14px);
                    width: calc(100% - 14px);
                }
            }

            .inner {
                padding: 35px 50px;
                text-align: center;
                justify-content: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                @include break(tablet) {
                    padding: 22px 30px;
                }

                .logo {
                    height: 60px;
                    margin-bottom: 25px;
                    flex-shrink: 0;
                    @include break(tablet) {
                        height: 40px;
                        margin-bottom: 10px;
                    }
                    svg {
                        height: auto;
                        @include break(tablet) {
                            width: 90px;
                        }
                        path {
                            fill: $dark;
                        }
                    }
                }

                .img_container {
                    width: 190px;
                    height: 215px;
                    position: relative;
                    margin-bottom: 25px;
                    flex-shrink: 0;
                    transition: all 0.6s;
                    @include break(tablet) {
                        width: 130px;
                        height: 150px;
                        margin-bottom: 15px;
                    }

                    img {
                        @include bg-img;
                    }
                    svg {
                        @include bg-img;
                        object-fit: contain;
                        width: 80%;
                    }
                }

                .content {
                    text-decoration: none;
                    height: 100%;
                    flex-shrink: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .desc {
                        font-size: 13px;
                        line-height: 150%;
                    }
                    .bottom {
                        padding-top: 15px;
                        @include break(tablet) {
                            padding-top: 5px;
                        }
                    }
                    .date {
                        font-size: 13px;
                        text-transform: uppercase;
                        color: $orange;
                        line-height: 120%;
                        @include font-medium;
                    }
                    .location {
                        font-size: 10px;
                        text-transform: uppercase;
                        color: #7A7A7A;
                        line-height: 120%;
                    }
                }
            }

            &:hover {
                .img_container {
                    transform: scale(1.025);
                }
            }
        }

        &.post_3 {
            padding-bottom: 50px;
            @include break(tablet) {
                padding-bottom: 0;
            }

            .card_1 {
                z-index: 5;
                order: 2;
                filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.1));

                &:hover {
                    filter: drop-shadow(0px 8px 30px rgba(0, 0, 0, 0.15));
                }
                @include break(tablet) {
                    z-index: 5;
                    order: 1;
                }
            }
            .card_2 {
                z-index: 4;
                order: 1;
                transform: rotate(-6deg) translate(30px, 40px);
                filter: drop-shadow(0px 8px 30px rgba(0, 0, 0, 0));
                &:hover {
                    filter: drop-shadow(0px 8px 30px rgba(0, 0, 0, 0.15));
                }

                @include break(tablet) {
                    z-index: 4;
                    order: 2;
                    transform: rotate(3deg) translate(20px, -25px) scale(0.93);
                }
            }
            .card_3 {
                z-index: 4;
                order: 3;
                transform: rotate(6deg) translate(-30px, 40px);
                filter: drop-shadow(0px 8px 30px rgba(0, 0, 0, 0));
                &:hover {
                    filter: drop-shadow(0px 8px 30px rgba(0, 0, 0, 0.15));
                }
                @include break(tablet) {
                    z-index: 3;
                    order: 3;
                    transform: rotate(6deg) translate(12px, -30px) scale(0.93);
                }
            }

        }

        &.post_2 {
            padding-bottom: 30px;
            @include break(tablet) {
                padding-bottom: 0;
            }
            .card_1 {
                transform: rotate(-6deg) translate(-50px, 0px);
                filter: drop-shadow(0px 8px 30px rgba(0, 0, 0, 0));
                &:hover {
                    filter: drop-shadow(0px 8px 30px rgba(0, 0, 0, 0.15));
                }
                @include break(tablet) {
                    z-index: 5;
                    transform: rotate(0deg);
                }
            }
            .card_2 {
                transform: rotate(6deg) translate(50px, 0px);
                filter: drop-shadow(0px 8px 30px rgba(0, 0, 0, 0));
                &:hover {
                    filter: drop-shadow(0px 8px 30px rgba(0, 0, 0, 0.15));
                }
                @include break(tablet) {
                    z-index: 4;
                    transform: rotate(3deg) translate(20px, -25px) scale(0.93);
                }
            }
        }

        &.post_1 {
            .card_1 {
                transition: all 0.4s;
                filter: drop-shadow(0px 8px 30px rgba(0, 0, 0, 0));
                &:hover {
                    filter: drop-shadow(0px 8px 30px rgba(0, 0, 0, 0.15));
                }
            }
        }
    }

    .bullet_nav {
        display: none;
        @include break(tablet) {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;

            .bullet {
                height: 6px;
                width: 6px;
                background-color: #7A7A7A;
                flex-grow: 0;
                flex-shrink: 0;
                border-radius: 50%;
                margin-left: 5px;
                margin-right: 5px;
                transition: all 0.4s;

                &.active {
                    background-color: $orange;
                }
            }
        }
    }
}