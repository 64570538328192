.module-06-banner {

    .container {
        position: relative;
        overflow: hidden;
        padding: 100px 5%;
        min-height: 470px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @include break(mobile) {
            padding: 60px 24px;
            min-height: 0px;
        }

        .bg {
            position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            img {
                @include bg-img;
                z-index: 0;
            }
    
            .filter {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                backdrop-filter: blur(4.5px);
            }
        }

        .content {
            color: $white;
            z-index: 2;
            width: 60%;
            @include break(tablet) {
                width: 100%;
            }

            .txt {
                padding-top: 24px;
            }
            
            .btn {
                margin-top: 40px;
                @include break(tablet) {
                    margin-top: 24px;
                }
            }
        }
    }
}