.module-03-content_cards {
    .cards_row {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin-bottom: -40px;
        margin-left: -10px;
        margin-right: -10px;

        .content_card {
            width: 360px;
            height: 460px;
            position: relative;
            border: 1px solid $orange;
            padding: 26px;
            margin-bottom: 40px;
            margin-left: 10px;
            margin-right: 10px;
            @include break(mobile){
                width: 100%;
                max-width: 330px;
                min-height: 440px;
                height: auto;
                padding: 22px;
            }

            .frame_el {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%,-50%);
                background-color: $tan;
                padding-left: 14px;
                padding-right: 14px;
            }

            .content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;

                .img-container {
                    width: 306px;
                    height: 180px;
                    position: relative;
                    overflow: hidden;
                    margin-bottom: 20px;
                    flex-shrink: 0;
                    img {
                        @include bg-img;
                    }
                    @include break(mobile){
                        margin-bottom: 18px;
                        width: 100%;
                        height: auto;
                        padding-bottom: 61%;
                    }
                }
                
                .txt {
                    padding-top: 8px;
                    height: 100%;
                    flex-shrink: 1;
                    overflow-x: hidden;
                    overflow-y: scroll; 
                    overflow: -moz-scrollbars-none;
                    font-size: 0.95em;
                    line-height: 135%;
                    @include break(mobile){
                        font-size: 16px;
                    }
                }
            }

            &.ghost {
                margin-left: 10px!important;
                margin-right: 10px!important;
            }
        }
    }

    .btn_container {
        padding-top: 60px;
        display: flex;
        justify-content: center;
        @include break(mobile){
            padding-top: 40px;
        }
    }
}