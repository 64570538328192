.quatre-cent-quatre {
  padding-top: 120px;
  padding-bottom: 120px;
  @include break(tablet) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include break(tablet) {
      flex-direction: column;
    }

    .content {
      h1 {
        margin-bottom: 24px;
      }
      .btn {
        margin-top: 40px;
      }
    }

    .dog {
          max-width: 480px;
          position: relative;
          @include break(tablet) {
            margin-top: 60px;
          }
          .dog_hat {
            position: absolute;
            top: -5%;
            left: 25%;
            width: 43%;
            transition: all 0.3s;
          }
          .dog_body, .dog_body svg, .dog_hat svg {
            width: 100%;
          }

          .woof {
            position: absolute;
            right: 0;
            bottom: 37%;
            @include font-titre;
            font-size: 1.65em;

            >div {
              opacity: 0;
            }

            .woof1 {
              animation: woof 0.3s 0.8s forwards;
            }
            .woof2 {
              margin-top: 5px;
              animation: woof 0.3s 1s forwards;
            }
          }

          &:hover {
            .dog_hat {
              transform: translateY(-50px);
            }
          }
    }
  }

}


@keyframes woof {
  0% {
    opacity: 0;
    transform: translateX(-20px) scale(0.7) skewX(-30deg);
  }
  100% {
    opacity: 1;
    transform: translateX(0px) scale(1) skewX(0deg);
  }
}