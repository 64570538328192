@font-face {
  font-family: 'BNBrick House';
  src: url('../fonts/BNBrickHouseRegular.eot');
  src: url('../fonts/BNBrickHouseRegular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/BNBrickHouseRegular.woff2') format('woff2'),
      url('../fonts/BNBrickHouseRegular.woff') format('woff'),
      url('../fonts/BNBrickHouseRegular.ttf') format('truetype'),
      url('../fonts/BNBrickHouseRegular.svg#BNBrickHouseRegular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'F37 Blanka';
  src: url('../fonts/F37Blanka-Regular.eot');
  src: url('../fonts/F37Blanka-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/F37Blanka-Regular.woff2') format('woff2'),
      url('../fonts/F37Blanka-Regular.woff') format('woff'),
      url('../fonts/F37Blanka-Regular.ttf') format('truetype'),
      url('../fonts/F37Blanka-Regular.svg#F37Blanka-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'F37 Blanka';
  src: url('../fonts/F37Blanka-SemiBold.eot');
  src: url('../fonts/F37Blanka-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/F37Blanka-SemiBold.woff2') format('woff2'),
      url('../fonts/F37Blanka-SemiBold.woff') format('woff'),
      url('../fonts/F37Blanka-SemiBold.ttf') format('truetype'),
      url('../fonts/F37Blanka-SemiBold.svg#F37Blanka-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'F37 Blanka';
  src: url('../fonts/F37Blanka-Medium.eot');
  src: url('../fonts/F37Blanka-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/F37Blanka-Medium.woff2') format('woff2'),
      url('../fonts/F37Blanka-Medium.woff') format('woff'),
      url('../fonts/F37Blanka-Medium.ttf') format('truetype'),
      url('../fonts/F37Blanka-Medium.svg#F37Blanka-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


@mixin font-text {
  font-family: 'F37 Blanka';
  font-weight: 400;
  font-style: normal;
}
@mixin font-regular {
  font-family: 'F37 Blanka';
  font-weight: 400;
  font-style: normal;
}
@mixin font-medium {
  font-family: 'F37 Blanka';
  font-weight: 500;
  font-style: normal;
}
@mixin font-semibold {
  font-family: 'F37 Blanka';
  font-weight: 600;
  font-style: normal;
}

@mixin font-titre {
  font-family: 'BNBrick House';
  font-weight: normal;
  font-style: normal;
}
