.template-contact {

    .locations_container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .contact_loc {
            width: 31.5%;
            border-top: 1px solid $orange;
            padding-top: 25px;
            margin-bottom: 60px;
            @include break(tablet) {
                width: 47.5%;
            }
            @include break(mobile) {
                width: 100%;
            }

            .logo {
                height: 50px;
                margin-bottom: 15px;
                width: 120px;
                img {
                    max-width: 100%;
                    filter: grayscale(1) brightness(0);
                }
                svg {
                    max-width: 100%;
                    path {
                        fill: $dark;
                    }
                }
            }
            .address {
                @include font-semibold;
                padding-bottom: 2px;
            }

            .hours {
                margin-top: 20px;

                .drop_click {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .title {
                        display: flex;
                        align-items: flex-start;

                        .state {
                            color: $orange;
                            font-size: 13px;
                            text-transform: uppercase;
                            margin-left: 10px;
                        }
                    }

                    .arrow {
                        transition: all 0.4s;
                        cursor: pointer;
                        svg path {
                            transition: fill 0.4s;
                        }

                        &:hover {
                            svg path {
                                fill: $orange;
                            }
                        }
                    }

                    &.open .arrow {
                        transform: rotate(180deg);
                    }
                }

                .drop_hours {
                    height: 0;
                    overflow: hidden;
                    .hours_day {
                        display: flex;
                        .day {
                            text-transform: capitalize;
                        }

                        &.active {
                            color: $orange;
                        }
                    }
                }
            }

            .btn_wrap {
                display: flex;
                align-items: center;
                margin-top: 25px;

                .btn_small {
                    background-color: $orange;
                    padding: 4px 14px;
                    border-radius: 50px;
                    @include font-titre;
                    font-size: 14px;
                    align-items: center;
                    color: $white;
                    text-decoration: none;
                    margin-right: 10px;
                    line-height: 150%;
                    font-size: 14px;
                    transition: background-color 0.4s, color 0.4s;
                    display: flex;

                    svg {
                    margin-left: 5px;
                    path {
                        transition: all 0.4s;
                    }
                    }

                    &:hover {
                    background-color: $white;
                    color: $orange;
                    svg path {
                        fill: $orange;
                    }
                    }
                }
            }

            .img_container {
                margin-top: 40px;
                width: 100%;
                padding-bottom: 100%;
                position: relative;
                img {
                    @include bg-img;
                }
            }
        }
    }

}
