footer {

    padding-top: 115px;
    padding-bottom: 40px;
    color: $white;
    @include break(tablet) {
        padding-top: 60px;
        padding-bottom: 30px;
    }

    .container_big {
        display: flex;
        justify-content: space-between;
        @include break(tablet) {
            flex-direction: column;
        }
    }

    .col1 {
        width: 450px;
        @include break(tablet) {
            width: 100%;
        }
        .form_wrap {
            .form_title {
                padding-bottom: 40px;
            }
            form {
                input, select {
                    appearance: none;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    font-size: 18px;
                    @include font-text;
                    transition: all 0.3s;
                    color: $white;
                    width: 100%;
                    &::placeholder {
                        transition: all 0.3s;
                        color: $white;
                        opacity: 1; /* Firefox */
                    }
                    
                    &::-ms-input-placeholder { /* Edge 12 -18 */
                        transition: all 0.3s;
                        color: $white;
                    }
                    &:focus {
                        color: $orange;
                        &::placeholder {
                            color: $orange;
                        }
                        
                        &::-ms-input-placeholder { 
                            color: $orange;
                        }
                    }
                    
                }

                ._error-inner {
                    margin-bottom: 26px;
                }

                ._field-wrapper {
                    padding-bottom: 11px;
                    border-bottom: 1px solid $white;
                    padding-top: 30px;
                    display: flex;
                    justify-content: space-between;
                    position: relative;

                    ._error {
                        position: absolute;
                        color: red;
                        left: 0;
                        bottom: 1px;
                        font-size: 9px;
                        pointer-events: none;
                    }
                }
                ._form_element:first-of-type ._field-wrapper {
                    padding-top: 0;
                }

                button {
                    margin-top: 30px;
                    position: relative;
                    appearance: none;
                    @include font-titre;
                    color: $tan;
                    font-size: 18px;
                    display: flex;
                    cursor: pointer;
                    transition: all 0.4s;
                    text-decoration: none;
                    background-color: transparent;
                    border: none;

                    .btn_txt {
                        display: flex;
                        background-color: $orange;
                        padding: 13.5px 15px;
                        transition: background-color 0.4s;
                        align-items: center;

                        svg {
                        margin-left: 10px;
                        path {
                            transition: all 0.4s;
                        }
                        }
                    }

                    .border {
                        height: auto;
                        path {
                        transition: all 0.4s;
                        }
                    }
                    .border1 {
                        margin-right: -1px;
                    }
                    .border2 {
                        transform: rotate(180deg);
                        margin-left: -1px;
                    }

                    &:hover {
                        color: $orange;
                        .btn_txt {
                        background-color: $white;
                        svg path {
                            fill: $orange;
                        }
                        }
                        .border path {
                        fill: $white;
                        }
                    }
                }
            }

            .select_wrap {
                position: relative;
                svg {
                    position: absolute;
                    pointer-events: none;
                    right: 0;
                    bottom: 17px;
                }
            }
        }

        .social_container {
            margin-top: 70px;
            display: flex;
            @include break(tablet) {
                margin-top: 40px;
                margin-bottom: 40px;
            }

            a {
                text-decoration: none;
                margin-right: 45px;
                svg {
                    transition: all 0.4s;
                    height: 23px;
                    path {
                        transition: all 0.4s;
                    }
                }

                &:hover {
                    svg {
                        transform: scale(1.1);
                        path {
                            fill: $orange;
                        }
                    }
                }
            }
        }
    }

    .col2 {
        .inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @include break(tablet) {
                width: 100%;
            }
    
            a {
                width: 45%;
                text-decoration: none;
                @include font-medium;
                transition: all 0.4s;
                margin-bottom: 25px;
    
                &:hover {
                    color: $orange;
                }
            }
        }
    }

    .bottom {
        padding-top: 230px;
        font-size: 13px;
        @include break(tablet) {
            padding-top: 40px;
        }

        .left {
            @include break(tablet) {
                margin-bottom: 10px;
            }
            a {
                text-decoration: none;
                @include font-medium;
                transition: all 0.4s;
                margin-right: 25px;
    
                &:hover {
                    color: $orange;
                }
            }
        }
    }
}