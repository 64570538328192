.template-archive-events-promotions {

    .hero {
        padding-top: 120px;
        padding-bottom: 105px;
        @include break (mobile) {
            padding-top: 105px;
            padding-bottom: 60px;
        } 
        .container {
            padding-top: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include break (mobile) {
                padding-top: 40px;
            }  
            h1 {
                width: 450px;
                margin-left: 10px;
                margin-right: 10px;
                text-align: center;
                @include break (tablet) {
                    width: 100%;
                    margin-left: 0px;
                    margin-right: 0px;
                }
            }
            svg {
                @include break (tablet) {
                    display: none;
                }
            }
        }
    }

    .filter_container {
        .select_wrap {
            position: relative;
            width: fit-content;
            svg {
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        select {
            appearance: none;
            border: 1px solid $orange;
            background-color: transparent;
            outline: none;
            padding: 4px 40px 3px 12px;
            border-radius: 4px;
        }
    }

    .archive {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: -20px;
        margin-right: -20px;
        margin-top: 40px;
        @include break(mobile) {
            margin-left: 0px;
            margin-right: 0px;
        }

        .article_card {
            width: 356px;
            height: 476px;
            border-radius: 4px;
            overflow: hidden;
            position: relative;
            text-decoration: none;
            color: $dark;
            flex-grow: 0;
            flex-shrink: 0;
            transition: filter 0.4s;
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 40px;
            display: none;
            @include break(tablet) {
                width: 280px;
                height: 370px;
                margin-left: 0px;
                margin-right: 0px;
            }

            .frame {
                position: absolute;
                top: 10px;
                left: 10px;
                right: 10px;
                bottom: 10px;
                z-index: 3;
                svg {
                    height: auto;
                }
                @include break(tablet) {
                    top: 7px;
                    left: 7px;
                    right: 7px;
                    bottom: 7px;
                    height: calc(100% - 14px);
                    width: calc(100% - 14px);
                }
            }

            .inner {
                padding: 35px 50px;
                text-align: center;
                justify-content: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                @include break(tablet) {
                    padding: 22px 30px;
                }

                .logo {
                    height: 60px;
                    margin-bottom: 25px;
                    flex-shrink: 0;
                    @include break(tablet) {
                        height: 40px;
                        margin-bottom: 10px;
                    }
                    svg {
                        height: auto;
                        @include break(tablet) {
                            width: 90px;
                        }
                        path {
                            fill: $dark;
                        }
                    }
                }

                .img_container {
                    width: 165px;
                    height: 185px;
                    position: relative;
                    margin-bottom: 25px;
                    flex-shrink: 0;
                    transition: all 0.6s;
                    @include break(tablet) {
                        width: 130px;
                        height: 150px;
                        margin-bottom: 15px;
                    }

                    img {
                        @include bg-img;
                    }
                    svg {
                        @include bg-img;
                        object-fit: contain;
                        width: 80%;
                    }
                }

                .content {
                    text-decoration: none;
                    height: 100%;
                    flex-shrink: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .desc {
                        font-size: 13px;
                        line-height: 150%;
                    }
                    .bottom {
                        padding-top: 15px;
                        @include break(tablet) {
                            padding-top: 5px;
                        }
                    }
                    .date {
                        font-size: 13px;
                        text-transform: uppercase;
                        color: $orange;
                        line-height: 120%;
                        @include font-medium;
                    }
                    .location {
                        font-size: 10px;
                        text-transform: uppercase;
                        color: #7A7A7A;
                        line-height: 120%;
                    }
                }
            }

            &:hover {
                .img_container {
                    transform: scale(1.025);
                }
            }

            &.ghost {
                margin-left: 20px!important;
                margin-right: 20px!important;
                @include break(mobile) {
                    display: none;
                }
            }

            &.visible {
                display: block;
            }
        }

    }

}